<template>
  <div class="page-box" :style="backgroundStyle">
    <div class="content">
      <div class="header">
        <div class="header-left">
          <div class="logo" @click="jumpFn('home')">
            <img :src="logo" alt="" />
          </div>
          <div class="live-title">江药四川直播间</div>
          <div class="active" @click="jumpFn('activity')">
            <i class="el-icon-present" color="#fff"></i>
            <span>活动中心</span>
          </div>
          <div class="home" @click="jumpFn('home')">
            <i class="el-icon-house" color="#fff"></i>
            <span>返回首页</span>
          </div>
          <div class="cart" @click="jumpFn('cart')">
            <div v-if="Number(num) > 0" class="cart-num">{{ num }}</div>
            <i class="el-icon-shopping-cart-2" size="16" color="#666"></i>
            <span style="margin-left: 6px">购物车</span>
          </div>
        </div>
        <div class="header-right">
          <div class="account-title">
            <i class="el-icon-user-solid" color="#fff"></i>
            <!-- 新都区新繁大药房三门店 -->
            <span style="font-size: 16px">{{ name }}</span>
          </div>
          <div>余额：{{ money }}</div>
        </div>
      </div>
      <div class="live-content">
        <div class="live-left" :style="playerStyle">
          <!--  -->
          <div v-if="couponFlag" class="coupon-box" @click="getLiveCouponData">
            <img :src="hblq" alt="" />
          </div>
          <!-- v-if="hbGetFlag" -->
          <div class="hb-get-box" v-if="hbGetFlag">
            <img :src="hbbg" class="hbbg-size" alt="图片走丢啦~" />
            <div class="goupon-text">
              <span>{{ couponData }}</span>
              <span class="unit-yuan">元</span>
            </div>
          </div>
          <div class="live-box">
            <img :src="liveData.playerbg" class="player-bg" alt="" />
            <aliplayer :liveData="this.liveData"></aliplayer>
          </div>
          <div class="live-bottom">
            <div class="bottom-item">
              <div>{{ liveData.title ? liveData.title : "暂无直播" }}</div>
              <!-- <div class="dm-box"><span>弹幕</span><el-switch v-model="dmFlag" active-color="#13ce66" inactive-color="#DCDFE6"> </el-switch></div> -->
            </div>
            <div class="bottom-item">
              <div class="gray-text">直播时间:{{ liveData.time_str }}</div>
              <!-- <div class="gray-text">462人正在观看</div> -->
            </div>
          </div>
        </div>
        <div class="live-right">
          <div class="title">
            <div @click="changeTab(1)" :class="[tabInd == 1 ? 'active1' : '']">推荐商品</div>
            <div @click="changeTab(2)" :class="[tabInd == 2 ? 'active2' : '']">聊天互动</div>
          </div>
          <div class="tab-content" v-if="tabInd == 1">
            <div class="goods-content">
              <div class="goods-item" v-for="item in goodsList" :key="item.id">
                <div class="img-box">
                  <img :src="item.logo" alt="" />
                </div>

                <div class="info-box">
                  <p class="name">{{ item.title }}</p>
                  <p>{{ item.scqy }}</p>
                  <!-- <p class="two">
                    <span>{{ item.sku }}</span>
                    <span>库存：{{ item.kc_str }}</span>
                  </p> -->
                  <div class="two">
                    <div class="two-cell-left">{{ item.sku }}</div>
                    <div class="two-cell-right">库存：{{ item.kc_str }}</div>
                  </div>
                  <div class="two">
                    <div class="two-cell-left">{{ item.validity_date }}</div>
                    <div v-if="item.limit_ct > 0" class="two-cell-right">限购：{{ item.limit_ct }}</div>
                  </div>
                  <!-- <p class="two">
                    <span>{{ item.validity_date }}</span>
                    <span>限购：{{ item.limit_ct }}</span>
                  </p> -->
                  <div class="price-box">
                    <div class="price-item">
                      <span class="price">{{ item.price }}</span>
                      <span v-if="item.line_price" class="line">原价:{{ item.line_price }}</span>
                    </div>
                    <div v-if="item.show_cart == 101" class="buy-btn" @click="openBuy(item)">立即抢购</div>
                    <div v-else class="can-not-buy-btn">立即抢购</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 对话框 -->
          <div class="tab-content tab-dialog-box" ref="scrollContainer" v-if="tabInd == 2">
            <div class="top-dialog">
              <div class="content-item" v-for="(item, index) in dialogList" :key="index">
                <span class="store-name">{{ item.dwmc }}:</span>
                <span class="store-txt">{{ item.text }}</span>
              </div>
            </div>
            <div class="bottom-inp">
              <input type="text" class="inp-box" v-model="textarea" v-on:keyup.enter="sendTxt" placeholder="请输入内容..." />
              <div class="send-btn" @click="sendTxt">发送</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="" :visible.sync="dialogFlag" width="550px">
      <div class="goods-dialog" style="width: 520px">
        <div class="dialog-left">
          <div class="dialog-goods-img">
            <img :src="goods.logo" alt="" />
          </div>
          <div style="margin-bottom: 6px">{{ goods.show_memo }}</div>
          <el-input-number v-model="goodsNum" @change="handleChange" :step="Number(goods.step)" :min="Number(goods.min_num)" :max="Number(goods.max_num)"></el-input-number>
        </div>
        <div class="dialog-right">
          <div class="pop-goods-name">{{ goods.title }}</div>

          <tagcom :tags="goods.tags"></tagcom>
          <div class="item-top">{{ goods.scqy }}</div>
          <div class="item-top line-box">
            <div class="line-item-left">规格：{{ goods.sku }}</div>
            <div class="line-item-right">库存：{{ goods.kc_str }}</div>
          </div>
          <div class="item-top line-box">
            <div class="line-item-left">有效期：{{ goods.validity_date }}</div>
            <div class="line-item-right">限购：{{ goods.limit_ct }}</div>
          </div>
          <div class="pop-goods-price">
            <span class="red-price">￥{{ goods.price }}</span>
            <span v-if="goods.line_price" class="line-price">原价：￥{{ goods.line_price }}</span>
          </div>
          <!-- <div class="item-top">库存：{{ goods.kc_str }}</div> -->
          <div class="buy-now-box" @click="buyFn">加入购物车</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import tagcom from "@/components/tag.vue";
import { LgetItem } from "@/utils/storage";
import { mapGetters } from "vuex";
import hbbg from "@/assets/img/home/hbbg.png";
import hblq from "@/assets/img/home/hblq.gif";
import aliplayer from "./components/aliplayer.vue";
export default {
  components: {
    tagcom,
    aliplayer,
  },
  data() {
    return {
      token: "",
      logo: "",
      live_id: "",
      dialogFlag: false,
      couponFlag: false,
      hbbg: hbbg,
      hblq: hblq,
      hbGetFlag: false,
      goodsNum: 0,
      goods: {}, //要购买的商品
      tabInd: 1,
      dmFlag: true,
      webSocket: null,
      socketOpen: false,
      textarea: "",
      liveData: {
        id: "",
        cover: "",
        pagebg: "",
        playerbg: "",
        source: "",
        title: "",
      },
      goodsList: [],
      dialogList: [], //直播对话
      couponData: "", //直播红包
      live_p: {},
    };
  },
  computed: {
    ...mapGetters(["num"]),
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.liveData.pagebg})`,
        backgroundSize: "cover",
      };
    },
    name() {
      if (LgetItem("info")) {
        // console.log("登录账号111-----", LgetItem("info"));
        return LgetItem("info").organization_name;
      } else {
        return "游客";
      }
    },
    money() {
      if (LgetItem("info")) {
        // console.log("登录账号111-----", LgetItem("info"));
        return LgetItem("info").money;
      } else {
        return 0;
      }
    },
    playerStyle() {
      return {
        backgroundImage: `url(${this.liveData.playerbg})`,
        backgroundSize: "cover",
      };
    },
  },
  watch: {
    dialogList: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          const container = this.$refs.scrollContainer;
          container.scrollTop = container.scrollHeight;
        });
      },
    },
  },

  created() {
    console.log("直播间参数------------------------", this.$route.query);
    this.live_p = this.$route.query;
    this.live_id = this.$route.query.live_id;
    this.logo = localStorage.getItem("small_logo");
    this.token = LgetItem("token");
  },
  mounted() {
    this.initWebSocket();
    window.addEventListener("onmessageWS", this.getSocketData);
    // 初始化播放器
    console.log("mounted-------");
  },

  methods: {
    // async loadWebPlayerSDK() {
    //   return new Promise((resolve) => {
    //     const s_tag = document.createElement("script"); // 引入播放器js
    //     s_tag.type = "text/javascript";
    //     s_tag.src = "https://g.alicdn.com/apsara-media-box/imp-web-player/2.20.3/aliplayer-min.js";
    //     // s_tag.charset = 'utf-8';
    //     s_tag.onload = () => {
    //       resolve();
    //     };
    //     document.body.appendChild(s_tag);
    //     const l_tag = document.createElement("link"); // 引入播放器css
    //     l_tag.rel = "stylesheet";
    //     l_tag.href = "https://g.alicdn.com/apsara-media-box/imp-web-player/2.20.3/skins/default/aliplayer-min.css";
    //     document.body.appendChild(l_tag);
    //   });
    // },
    initWebSocket() {
      // let token = LgetItem("token");
      // console.log(token);
      this.$websocket.initWebSocket();
      this.getLiveData();
      this.getLiveGoodsData();
      this.getLiveDialogsData();
    },
    // 发送消息
    sendTxt() {
      let msg = { live_id: this.live_p.live_id, type: "say", token: this.token, text: this.textarea };
      this.$websocket.sendWebsocket(JSON.stringify(msg));
      this.textarea = "";
      this.$websocket.webSocketOnMessage();
    },
    getSocketData(res) {
      let data = res.detail;
      console.log("页面接收返回的消息---", data);
      if (data.data.type == "dialog") {
        this.dialogList.push(data.data.msg);
      }
      if (data.data.type == "goods") {
        this.getLiveGoodsData();
      }
      if (data.data.type == "redpack") {
        this.redpack_id = data.data.redpack_id;
        this.couponFlag = true;
      }
    },
    // 直播信息
    getLiveData() {
      this.$api("home.getliveInfo", { id: this.live_p.live_id }).then((res) => {
        console.log("直播信息-----", res);
        if (res.code == 200) {
          this.liveData = res.data;
        }
      });
    },
    // 直播商品
    getLiveGoodsData() {
      this.$api("home.getliveGoods", { id: this.live_p.live_id }).then((res) => {
        console.log("直播商品-----", res);
        if (res.code == 200) {
          this.goodsList = res.data;
        }
      });
    },
    // 直播历史对话
    getLiveDialogsData() {
      this.$api("home.getliveDialogs", { id: this.live_p.live_id }).then((res) => {
        console.log("直播历史对话-----", res);
        if (res.code == 200) {
          this.dialogList = res.data;
        }
      });
    },
    // 直播抢红包
    getLiveCouponData() {
      this.$api("home.getliveCoupon", { id: this.live_p.live_id, redpack_id: this.redpack_id }).then((res) => {
        console.log("直播红包-----", res);
        this.hbGetFlag = true;

        if (res.code == 200) {
          this.couponData = res.data;
          this.couponFlag = false;
          this.hbGetFlag = true;
          setTimeout(() => {
            this.hbGetFlag = false;
          }, 3000);
        }
      });
    },
    // 打开购买商品的弹窗
    openBuy(data) {
      this.goods = data;
      console.log("商品---", data);
      this.goodsNum = Number(data.min_num);
      this.dialogFlag = true;
    },
    handleChange(e) {
      console.log(e);
    },
    // 加入购物车
    buyFn() {
      if (this.goods.show_cart != 101) return;
      let p = {
        id: this.goods.id,
        number: this.goodsNum,
      };
      this.$api("goods.addCart", p).then((res) => {
        if (res.code == 200) {
          this.dialogFlag = false;
          this.$message.success("加入购物车成功");
          this.$store.dispatch("getBadge");
          this.goodsNum = 0;
          this.goods = {};
        }
      });
    },
    jumpFn(str) {
      if (str == "home") {
        // this.$router.push({
        //     path: "/index/subRouter/order",
        //     query: { index: 1 },
        //   });
        this.$router.push({
          path: "/",
        });
      }
      if (str == "activity") {
        // this.$router.push({
        //   path: "/index/activityCenter",
        // });
        const { href } = this.$router.resolve({
          path: "/index/activityCenter",
        });
        window.open(href, "_blank");
      }
      if (str == "cart") {
        this.$router.push({
          path: "/index/cart",
        });
      }
    },
    changeTab(num) {
      this.tabInd = num;
    },
  },
  destroyed() {
    this.$websocket.close();
  },
};
</script>
<style lang="less" scoped>
.page-box {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .content {
    width: 1200px;
    margin: 0 auto;
    .header {
      width: 100%;
      height: 67px;
      line-height: 67px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .header-left {
        width: 65%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo {
          width: 243px;
          height: 67px;
        }
        .live-title {
          font-size: 24px;
          font-weight: bold;
          color: #fff;
        }
        .active {
          display: flex;
          font-size: 14px;
          height: 60px;
          line-height: 60rpx;
          align-items: center;
          color: #fff;
          cursor: pointer;
          span {
            margin-left: 6px;
          }
        }
        .home {
          display: flex;
          font-size: 14px;
          height: 60px;
          line-height: 60rpx;
          align-items: center;
          color: #fff;
          cursor: pointer;
          span {
            margin-left: 6px;
          }
        }
        .cart {
          cursor: pointer;
          display: flex;
          height: 32px;
          text-align: center;
          line-height: 32px;
          font-size: 14px;
          padding: 0 10px;
          // padding: 10px 18px;
          border-radius: 20px;
          align-items: center;
          color: #666;
          background-color: #fff;
          position: relative;
          .cart-num {
            position: absolute;
            right: -15px;
            top: -5px;
            height: 18px;
            line-height: 18px;
            padding: 0 6px;
            text-align: center;
            white-space: nowrap;
            border-radius: 10px;
            background-color: #f56c6c;
            color: #fff;
            font-size: 12px;
          }
        }
      }
      .header-right {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        color: #fff;
        .account-title {
          margin-right: 10px;
        }
      }
    }
    .live-content {
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      //   height: 200px;
      //   background-color: antiquewhite;
      .live-left {
        width: 853px;
        height: 730px;
        border-radius: 15px;
        overflow: hidden;
        background-repeat: no-repeat;
        position: relative;
        .coupon-box {
          width: 100px;
          height: 100px;
          // background-color: #f56c6c;
          position: absolute;
          top: 20px;
          left: 20px;
          z-index: 99;
        }
        .live-box {
          // width: 100%;
          height: 640px;
          margin: 0 auto;
          // background-color: black;
          position: relative;
          .player-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .live-bottom {
          width: 100%;
          height: 90px;
          background-color: #fff;
          padding: 10px 18px;
          box-sizing: border-box;
          .bottom-item {
            width: 100%;
            display: flex;
            font-size: 16px;
            margin-top: 5px;
            align-items: center;
            justify-content: space-between;
            .dm-box {
              display: flex;
              align-items: center;
              justify-content: center;
              span {
                margin-right: 10px;
              }
            }
            .gray-text {
              font-size: 12px;
              margin-top: 10px;
              color: #666;
            }
          }
        }
      }
      .live-right {
        width: 327px;
        height: 730px;
        background-color: #fff;
        border-radius: 15px 15px 15px 15px;
        overflow: hidden;
        position: relative;
        .title {
          width: 100%;
          height: 40px;
          line-height: 40px;
          background: #e5e5e5;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          div {
            width: 50%;
            // height: 100%;
            text-align: center;
            // height: 100%;
            font-size: 16px;
            cursor: pointer;
            font-weight: 700;
            color: #666666;
            line-height: 40px;
          }
          .active1 {
            background-image: url(../../assets/img/zone/left.png);
            width: 187px;
            color: #12288a;
          }
          .active2 {
            background-image: url(../../assets/img/zone/right.png);
            width: 187px;
            color: #12288a;
          }
        }
        .tab-dialog-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .tab-content {
          width: 100%;
          height: 100%;
          height: 690px;
          // height: calc(100% - 40px);
          background-color: #f7f7f7;
          //   background-color: #f43939;
          overflow-y: auto;
          .goods-content {
            width: 100%;
            height: 100%;
            .goods-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              background-color: #fff;
              margin-top: 10px;
              box-shadow: 0px 1px 5px #ccc;
              .img-box {
                width: 90px;
                height: 90px;
                margin: 0 5px;
              }
              .info-box {
                flex: 1;
                padding: 10px 26px 10px 0;
                box-sizing: border-box;
                .name {
                  font-size: 16px;
                  font-weight: bold;
                  color: #333;
                }
                .two {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .two-cell-left {
                    width: 60%;
                    font-size: 12px;
                    margin-top: 4px;
                    // background-color: #f56c6c;
                  }
                  .two-cell-right {
                    width: 40%;
                    font-size: 12px;
                    margin-top: 4px;
                    // background-color: #f56c6c;
                  }
                }
                p {
                  color: #666;
                  font-size: 12px;
                  margin-top: 4px;
                }
                .price-box {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 3px;
                  .price-item {
                    font-size: 12px;
                    .price {
                      color: #ff1919;
                      font-weight: bold;
                      font-size: 16px;
                    }
                    .line {
                      color: #666;
                      text-decoration: line-through;
                      margin-left: 4px;
                    }
                  }
                  .buy-btn {
                    padding: 4px 15px;
                    cursor: pointer;
                    color: #fff;
                    border-radius: 100px;
                    background-color: #ff1919;
                    white-space: nowrap;
                  }
                  .can-not-buy-btn {
                    padding: 4px 15px;
                    cursor: pointer;
                    color: #999;
                    border-radius: 100px;
                    background-color: #dddddd;
                    white-space: nowrap;
                  }
                }
              }
            }
          }
          .top-dialog {
            width: 100%;
            height: 90%;
            width: 307px;
            padding: 0 10px;
            overflow-y: auto;
            background-color: #f5f5f5;
            .content-item {
              width: 100%;
              // display: flex;
              margin: 7px 0;
              .store-name {
                color: #12288a;
                font-size: 16px;
              }
              .store-txt {
                color: #444444;
                word-wrap: break-word;
                font-size: 16px;
              }
            }
          }
          .bottom-inp {
            width: 100%;
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background-color: #fff;
            .inp-box {
              width: 75%;
              border-radius: 40px;
              height: 35px;
              line-height: 35px;
              padding-left: 10px;
              background-color: #f2f2f2;
              background-color: #f5f5f5;
              border: none;
              outline: none;
            }
            .send-btn {
              cursor: pointer;
              font-size: 16px;
              color: #13ce66;
            }
          }
        }
      }
    }
  }
  // /deep/.el-input__inner {
  //   border: none;
  //   box-shadow: none;
  // }
  .goods-dialog {
    display: flex;
    // justify-content: space-between;
    // background-color: blueviolet;
    .dialog-left {
      width: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .dialog-goods-img {
        width: 160px;
        height: 160px;
      }
    }
    .dialog-right {
      width: 280px;
      margin-left: 10px;
      .pop-goods-name {
        font-size: 18px;
        color: #333;
        font-weight: bold;
      }
      .pop-goods-price {
        display: flex;
        align-items: center;
        margin-top: 6px;
        .red-price {
          color: red;
          font-size: 28px;
          font-weight: bold;
          font-style: italic;
        }
        .line-price {
          font-size: 12px;
          color: #666666;
          text-decoration: line-through;
          margin-left: 10px;
        }
      }
      .item-top {
        margin-top: 6px;
      }
      .line-box {
        // background-color: #f56c6c;
        display: flex;
        justify-content: space-between;
        .line-item-left {
          width: 65%;
        }
        .line-item-right {
          width: 30%;
        }
      }
      .buy-now-box {
        cursor: pointer;
        margin-top: 10px;
        width: 120px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 4px;
        background-color: #ff1919;
        color: #fff;
        width: 100%;
      }
    }
  }
  .ad-content {
    background-color: rgba(31, 29, 29, 0.8);
    z-index: 9998;
    width: 99vw;
    height: 100vw;
    position: fixed;
    padding-top: 200px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    .ad-img {
      width: 400px;
      height: 401px;
      margin: 0 auto;
      // margin-top: 50%;
      // margin-left: 50%;
      vertical-align: middle;
      position: relative;
      .close-ad {
        position: absolute;
        cursor: pointer;
        font-size: 24px;
        top: -10px;
        color: #fff;
        z-index: 99;
        right: 0;
      }
    }
  }
  .hb-get-box {
    width: 300px;
    height: 300px;
    // background-color: blueviolet;
    position: absolute;
    top: 50%;
    margin-top: -200px;
    left: 50%;
    margin-left: -150px;
    z-index: 99;
    text-align: center;
    .hbbg-size {
      width: 200px;
      height: 201px;
    }
    .goupon-text {
      width: 150px;
      height: 70px;
      text-align: center;
      line-height: 70px;
      // background-color: #12288a;
      position: absolute;
      top: 60px;
      left: 50%;
      font-size: 40px;
      margin-left: -75px;
      color: #db2520;
      .unit-yuan {
        font-size: 20px;
      }
    }
  }
}
// ::v-deep .el-dialog__wrapper {
//   background-color: transparent !important;
// }

// ::v-deep .el-dialog {
//   background-color: transparent !important;
//   border: none !important;
// }

// ::v-deep .el-overlay {
//   background-color: transparent !important;
// }
/deep/.el-dialog__header {
  padding: 0;
}
</style>
<style scoped>
/* .transparent-dialog /deep/ .el-dialog {
  background-color: transparent;
  border: none;
  box-shadow: none; 
}

.transparent-dialog /deep/ .el-dialog__header {
  display: none; 
}

.transparent-dialog /deep/ .el-dialog__body {
  padding: 0; 
}

.transparent-dialog /deep/ .el-dialog__footer {
  display: none; 
}
.transparent-dialog {
  .img-hb-box {
    position: relative;
    .hb-num {
      width: 200px;
      height: 40px;
      position: absolute;
      top: 30%;
      left: 50%;
      margin-left: -100px;
      color: #d92e24;
      font-size: 90px;
      font-weight: bold;
    }
  }
} */
</style>

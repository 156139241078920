<template>
  <div v-if="!isShowMultiple && show">
    <!-- <template v-if="!isShowMultiple && show"> -->
    <vue-aliplayer-v2 :source="source" ref="VueAliplayerV2" style="height: 640px; width: 340px; margin: 0 auto" :options="options" />
    <!-- </template> -->
  </div>
</template>
<script>
import VueAliplayerV2 from "vue-aliplayer-v2";
export default {
  components: {
    // VueAliplayerV2,
    VueAliplayerV2: VueAliplayerV2.Player,
  },
  props: ["liveData"],
  data() {
    return {
      liveInfo: {},
      options: {
        source: "",
        isLive: true, //切换为直播流的时候必填
        format: "m3u8", //切换为直播流的时候必填
        cover: "",
        // format: "rtmp", //切换为直播流的时候必填
      },
      source: "",
      show: true,
      isShowMultiple: false,
    };
  },
  watch: {
    liveData: {
      handler(newVal) {
        this.liveInfo = newVal;
        this.options.source = newVal.source;
        this.options.cover = newVal.cover;
        this.source = newVal.source;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.options.source = this.liveData.source;
    this.source = this.liveData.source;
    // this.play();
  },
  mounted() {
    if (this.source) {
      this.options.source = this.liveData.source;
      this.source = this.liveData.source;
      this.play();
    }
  },
  beforeDestroy() {
    this.$refs.VueAliplayerV2.destroy();
    // }
  },

  methods: {
    play() {
      this.$refs.VueAliplayerV2.play();
    },

    pause() {
      this.$refs.VueAliplayerV2.pause();
    },

    replay() {
      this.$refs.VueAliplayerV2.replay();
    },

    getCurrentTime() {
      // this.$refs.VueAliplayerV2.getCurrentTime();
      this.source = "https://video.wisclub.cn/jiscom/0828.m3u8?auth_key=1724841504-0-0-496126e0c455ddf275d5d807a9dfa41a";
    },

    getStatus() {
      const status = this.$refs.VueAliplayerV2.getStatus();
      console.log(`getStatus:`, status);
      alert(`getStatus:${status}`);
    },

    showMultiple() {
      this.isShowMultiple = !this.isShowMultiple;
    },
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
.remove-text {
  text-align: center;
  padding: 20px;
  font-size: 24px;
}
.show-multiple {
  display: flex;
  .multiple-player {
    width: calc(100% / 4);
    margin: 20px;
  }
}
.player-btns {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  span {
    margin: 0 auto;
    display: inline-block;
    padding: 5px 10px;
    width: 150px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #eee;
    background: #e1e1e1;
    border-radius: 10px;
    text-align: center;
    margin: 5px;
    cursor: pointer;
  }
}
.source-box {
  padding: 5px 10px;
  margin-bottom: 20px;
  .source-label {
    margin-right: 20px;
    font-size: 16px;
    display: block;
  }
  #source {
    margin-top: 10px;
  }
  .source-input {
    margin-top: 10px;
    padding: 5px 10px;
    width: 80%;
    border: 1px solid #ccc;
  }
}
</style>
